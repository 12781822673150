const TOKEN = 'token';
const USER_INFO = 'userInfo';


function get(key) {
    return localStorage.getItem(key);
}


function set(key, value) {
    localStorage.setItem(key, value);
}


function remove(key) {
    localStorage.removeItem(key);
}


function setToken(token) {
    set(TOKEN, token);
}


function getToken() {
    return get(TOKEN);
}


function removeToken() {
    remove(TOKEN);
}


function setUserInfo(userInfo) {
    userInfo = JSON.stringify(userInfo);
    set(USER_INFO, userInfo);
}


function getUserInfo() {
    const userInfo = get(USER_INFO);
    return(JSON.parse(userInfo));
}


function removeUserInfo() {
    remove(USER_INFO);
}

export default {
    get, set, remove, setToken, getToken, removeToken,
    setUserInfo, getUserInfo, removeUserInfo
}