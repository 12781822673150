import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App.vue';
import router from './router';
import moment from 'moment';
import VueClipboard from 'vue-clipboard2';
import VueViewer from 'v-viewer';
import 'ant-design-vue/dist/antd.less';
import './assets/styles/common.styl';
import 'viewerjs/dist/viewer.css';

moment.locale('zh-cn');
Vue.filter('moment', function(value) {
  return moment(value).format('lll');
});

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueClipboard);
Vue.use(VueViewer);
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
});
window._AMapSecurityConfig = {
  securityJsCode: 'f8ccd40a46169ab3b8bde686e6915d8e',
};

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
