import Vue from 'vue';
import VueRouter from 'vue-router';

import storage from '@/utils/storage';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index/Index'),
    meta: {
      title: '首页 - 病媒生物数字化标本库',
      level: 2
    }
  },
  {
    path: '/account/login',
    name: 'Login',
    component: () => import('@/views/Login/Login'),
    meta: {
      title: '请登录 - 病媒生物数字化标本库',
      level: 0
    }
  },
  {
    path: '/tree',
    name: 'Tree',
    component: () => import('@/views/Tree/Tree'),
    meta: {
      title: '按物种树浏览 - 病媒生物数字化标本库',
      level: 2
    }
  },
  {
    path: '/distribution',
    name: 'Distribution',
    component: () => import('@/views/Distribution/Distribution'),
    meta: {
      title: '按物种分布地浏览 - 病媒生物数字化标本库',
      level: 2
    }
  },
  {
    path: '/collection',
    name: 'Collection',
    component: () => import('@/views/Collection/Collection'),
    meta: {
      title: '按物种采集地浏览 - 病媒生物数字化标本库',
      level: 2
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/Search/Search'),
    meta: {
      title: '搜索 - 病媒生物数字化标本库',
      level: 2
    }
  },
  {
    path: '/searchList',
    name: 'SearchList',
    component: () => import('@/views/Search/SearchList'),
    meta: {
      title: '搜索 - 病媒生物数字化标本库',
      level: 2
    }
  },
  {
    path: '/species/:slug',
    name: 'SpeciesDetail',
    component: () => import('@/views/SpeciesDetail/SpeciesDetail'),
    meta: {
      title: '物种详情 - 病媒生物数字化标本库',
      level: 2
    }
  },
  {
    path: '/align',
    name: 'Align',
    component: () => import('@/views/Align/Align'),
    meta: {
      title: '比对 - 病媒生物数字化标本库',
      level: 2
    }
  },
  {
    path: '/aligns/:taskId',
    name: 'AlignResult',
    component: () => import('@/views/AlignResult/AlignResult'),
    meta: {
      title: '比对结果 - 病媒生物数字化标本库',
      level: 2
    }
  },
  {
    path: '/admin/taxons',
    name: 'TaxonAdmin',
    component: () => import('@/views/TaxonAdmin/TaxonAdmin'),
    meta: {
      title: '分类管理 - 病媒生物数字化标本库',
      level: 3
    }
  },
  {
    path: '/admin/species',
    name: 'SpeciesAdmin',
    component: () => import('@/views/SpeciesAdmin/SpeciesAdmin'),
    meta: {
      title: '物种管理 - 病媒生物数字化标本库',
      level: 3
    }
  },
  {
    path: '/admin/species/:species/pics',
    name: 'PicAdmin',
    component: () => import('@/views/PicAdmin/PicAdmin'),
    meta: {
      title: '图片管理 - 病媒生物数字化标本库',
      level: 3
    }
  },
  {
    path: '/admin/species/:species/seqs',
    name: 'SeqAdmin',
    component: () => import('@/views/SeqAdmin/SeqAdmin'),
    meta: {
      title: '序列管理 - 病媒生物数字化标本库',
      level: 3
    }
  },
  {
    path: '/admin/users',
    name: 'UserAdmin',
    component: () => import('@/views/UserAdmin/UserAdmin'),
    meta: {
      title: '用户管理 - 病媒生物数字化标本库',
      level: 3
    }
  },
  {
    path: '/permission-denied',
    name: 'PermissionDenied',
    component: () => import('@/views/PermissionDenied/PermissionDenied'),
    meta: {
      title: '禁止访问 - 病媒生物数字化标本库',
      level: 0
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


function getLevel(userInfo) {
  if (userInfo) {
    if (userInfo['is_superuser']) {
      return 3;
    }
    if (userInfo['is_staff']) {
      return 2;
    }
    return 1;
  }
  return 0;
}


router.beforeEach((to, from, next) => {
  const userInfo = storage.getUserInfo();
  const level = getLevel(userInfo);
  if (level >= to.meta['level']) {
    next();
  } else {
    if (userInfo) {
      next({name: 'PermissionDenied'});
    } else {
      next({name: 'Login'});
    }
  }
});

export default router
